// import { computed } from "vue";
import { getItem } from "./localStorageControl";
/**
 * Return  Access Permission of a given route
 * @param {string} slug
 */
const canUserAccess = (slug, pType) => {
  // console.log(slug, pType)
  // return true
  const permissionList = getItem("permission")

  if (slug.path == '/')
    return true
  else {
    if (["profile", "change-password", "logout"].includes(slug.path.split("/")[1])) {
      return true;
    }
    const module = permissionList?.find(module => module.name === "Production");
    if (!module) {
      return false;
    }
    const action = module.module_master.find(action => action.slug === slug.path.split("/")[1]);
    if (!action) {
      return false;
    }
    return action.permission[pType]
  }
};

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */

// const ellipsisParagraph = (text, size) => {
//   return `${text.split(' ').slice(0, size).join(' ')}...`;
// };

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */

const ellipsis = (text, size) => {
  return `${text.slice(0, size)}...`;
};

/**
 * Return idGenerator of a given id of length
 * @param {array} data
 * @param {number} length
 */

const idGenerator = (data, length = 1) => {
  const arrayData = [];
  data.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};

/**
 * Return camelSentence of a given string
 * @param {string} text
 */

import { Excel } from "antd-table-saveas-excel";
const excel = new Excel();
function exportExcel(columns, data, removeCols) {
  let cols = [];
  columns.forEach((i) => {
    cols.push(i);
  });
  removeCols?.length &&
    removeCols.forEach((x) => {
      cols.forEach((item, n) => {
        if (x == item.key) {
          cols.splice(n, 1);
          console.table(cols);
        }
      });
    });
  var d = new Date(); // for now
  let h = d.getHours();
  let m = d.getMinutes();
  let s = d.getSeconds();
  console.log(`${h}:${m}:${s}`);
  excel
    .addSheet(`${h}:${m}:${s}filename`)
    .addColumns(cols)
    .addDataSource(data, {
      str2Percent: true,
    })
  // .saveAs(`${filename}_${today}.xlsx`);
}
const camelSentence = function camelSentence(text) {
  return (" " + text)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
};

function capitalize(val) {
  return val
    .split("_")
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
}

function getServerImages(image) {
  return process.env.VUE_APP_S3_PATH + '/' + image;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
/**
 * Return camelSentence of a given string
 * @param {string} text
 */

const setJson = function setJson(originalJson) {
  const groupedJson = {};
  // Loop through each object in the original JSON and group them based on module_id and module_masters_id
  originalJson.forEach((item) => {
    if (!groupedJson[item[1].module_id]) {
      // If the module_id is not already present in the grouped JSON, create a new object for it
      groupedJson[item[1].module_id] = {
        module_id: item[1].module_id,
        name: item[1].module_name,
        module_master: [],
      };
    }
    const moduleMasterIndex = groupedJson[
      item[1].module_id
    ].module_master.findIndex(
      (master) => master.id === item[1].module_master_id
    );
    if (moduleMasterIndex === -1) {
      // If the module_masters_id is not already present in the module_master array, create a new object for it
      groupedJson[item[1].module_id].module_master.push({
        id: item[1].module_master_id,
        name: item[1].module_master_title,
        slug: item[1].slug,
        permission: {
          add: item[1].add,
          edit: item[1].edit,
          view: item[1].view,
          remove: item[1].remove,
        },
      });
    } else {
      // If the module_masters_id is already present in the module_master array, update its permission object
      groupedJson[item[1].module_id].module_master[
        moduleMasterIndex
      ].permission = {
        add: item[1].add,
        edit: item[1].edit,
        view: item[1].view,
        remove: item[1].remove,
      };
    }
  });

  // Convert the groupedJson object to an array of objects
  return Object.values(groupedJson);
};

export {
  canUserAccess,
  ellipsis,
  idGenerator,
  camelSentence,
  setJson,
  capitalize,
  formatDate,
  getServerImages,
  exportExcel,
};
