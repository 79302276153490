import { defineStore } from "pinia";
import staticData from '@/config/config';
export const themeStore = defineStore("theme", {
    state: () => ({
        data: staticData.darkMode,
        rtlData: staticData.rtl,
        topMenu: staticData.topMenu,
        main: staticData.mainTemplate,
        sidebar: staticData.sidebarTemplate,
        topBar: staticData.topBarTemplate,
        footer: staticData.footerTemplate,
        loading: false,
        rtlLoading: false,
        menuLoading: false,
        error: null,
        toggleMenu : false
    }),

    getters: {
        getRtl() {
            return this.rtlData
        },
        getTopMenu() {
            return this.topMenu;
        },
        getMain() {
            return this.main;
        },
        getData() {
            return this.data;
        },
        getLoading() {
            return this.loading;
        },
    },

    actions: {
        async changeLayoutMode(value) {
            try {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.data = value;
                    this.main = value ? 'blackMode' : 'lightMode';
                }, 10);
            } catch (err) {
                this.loading = false;
                this.error = err;
            }
        },

        async changeRtlMode(value) {
            try {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.rtlData = value;
                }, 10);
            } catch (err) {
                this.loading = false;
                this.error = err;
            }
        },

        async changeMenuMode(value) {
            try {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.topMenu = value;
                }, 10);
            } catch (err) {
                this.loading = false;
                this.error = err;
            }
        },
    },
});
