import { createWebHistory, createRouter } from "vue-router";
import routes from './routes'
import { getItem } from "../utility/localStorageControl";
import { message } from "ant-design-vue";
import { canUserAccess } from "../utility/utility";

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL
  ),
  linkExactActiveClass: "active",
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!getItem("access_token")) {
//       message.error(
//         "You must be logged in to view this page.",
//       );
//       return next({
//         name: "login",
//       });
//     }
//     else if (await canUserAccess({ path: to.path }, "view")) {
//       next();
//     }
//     else {
//       router.push({ name: 'NotFound' })
//     }
//   } else if (
//     to.matched.some((record) => record.meta.requireGuest) &&
//     getItem("access_token")
//   ) {
//     return next({
//       name: "dashboard",
//     });
//   }
//   else {
//     next()
//   }

//   // else if (
//   //   (to.name == "two-factor-authentication" && !getItem("access_token")) ||
//   //   (to.name == "two-step-verification" && !getItem("access_token"))
//   // ) {
//   //   return next({
//   //     name: "login",
//   //   });
//   // } else {
//   //   if (to.name == "two-factor-authentication" && getItem("isTwoFactor")) {
//   //     next({
//   //       name: "dashboard",
//   //     });
//   //   } else if (
//   //     to.name == "two-step-verification" &&
//   //     getItem("confirmTwoFactor")
//   //   ) {
//   //     next({
//   //       name: "dashboard",
//   //     });
//   //   }
//   //   next();
//   // }

// });
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!getItem("access_token")) {

      message.error(
        "You must be logged in to view this page.",
      );
      return next({
        name: "login",
      });
    } else if (!getItem("isTwoFactor")) {
      next({
        name: "two-factor-authentication",
      });
    } else if (!getItem("confirmTwoFactor")) {
      next({
        name: "two-step-verification",
      });
    } else if (getItem("LockScreen") == true) {
      next(false);
    }
    else {
      if (await canUserAccess({ path: to.path }, "view")) {
        // console.log('hii')
        next();
      }
      else {
        router.push({ name: 'Access NotFound' })
      }
    }
  } else if (
    to.matched.some((record) => record.meta.requireGuest) &&
    getItem("access_token")
  ) {
    return next({
      name: "dashboard",
    });
  } else if (
    (to.name == "two-factor-authentication" && !getItem("access_token")) ||
    (to.name == "two-step-verification" && !getItem("access_token"))
  ) {
    return next({
      name: "login",
    });
  } else {
    if (to.name == "two-factor-authentication" && getItem("isTwoFactor")) {
      next({
        name: "dashboard",
      });
    } else if (
      to.name == "two-step-verification" &&
      getItem("confirmTwoFactor")
    ) {
      next({
        name: "dashboard",
      });
    }
    next();
  }

});


export default router;
