
const routes = [
  {
    name: "Admin",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/layout/withAdminLayout.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/Dashboard.vue"
          ),
        children: [
          {
            path: "/",
            name: "dashboard",
            component: () =>
              import(
                /* webpackChunkName: "productionDashboard" */ "@/view/dashboard/productionDashboard.vue"
              ),
          },
          {
            path: "profile",
            name: "profile",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/profile.vue"
              ),
          },
          {
            path: "change-password",
            name: "change-password",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/ChangePassword.vue"
              ),
          },
          {
            path: "catalog-add",
            name: "catalog-add",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/CatalogAdd.vue"
              ),
          },
          {
            path: "add-video",
            name: "add-video",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/addVideo.vue"
              ),
          },
          {
            path: "add-kidNonkid",
            name: "add-kidNonkid",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/addkidNonkid.vue"
              ),
          },
          {
            path: "steps-wizads",
            name: "steps-wizads",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/StepsWizads.vue"
              ),
          },
          {
            path: "catalog-list",
            name: "catalog-list",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/CatalogList2.vue"
              ),
          },
          {
            path: "assign-designer",
            name: "assign-designer",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/CatalogList2.vue"
              ),
          },

          {
            path: "add-new-video",
            name: "add-new-video",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/AddNewVideos.vue"
              ),
          },

          {
            path: "view-designer",
            name: "view-designer",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/ViewDesigners.vue"
              ),
          },
          {
            path: "assign-kids-nonkids",
            name: "assign-kids-nonkids",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/AssignKidsNonkids.vue"
              ),
          },
          {
            path: "add-video-list",
            name: "add-video-list",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/AddVideoList.vue"
              ),
          },
        ],
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    name: "Auth",
    path: "/auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/layout/withAuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/view/authentication/SignIn.vue"
          ),
        meta: { requireGuest: true },
      },
      {
        path: "/two-factor-authentication",
        name: "two-factor-authentication",
        component: () =>
          import(
            /* webpackChunkName: "twoStepAuthentication" */ "@/view/authentication/TwoFactorAuthentication.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "/two-step-verification",
        name: "two-step-verification",
        component: () =>
          import(
            /* webpackChunkName: "twoStepVerification" */ "@/view/authentication/TwoStepVerification.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "/logout",
        name: "logout",
        component: () =>
          import(
            /* webpackChunkName: "logout" */ "@/view/authentication/SignOut.vue"
          ),
        // meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/page-not-found",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/view/pages/404.vue"),
  },
];

export default routes;
